import React, { useState, useEffect } from 'react';
import axios from './axios';
import { useNavigate } from 'react-router-dom';
import { isAuth } from './Auth';
import Swal from 'sweetalert2';

function AddUser() {
    const navegar = useNavigate();
    const [nombre, setNombre] = useState('');
    const [numRecibo, setNumRecibo] = useState('');
    const [fecha, setFecha] = useState(() => {
        const hoy = new Date();
        return hoy.toISOString().split('T')[0];
    });
    const [servicios, setServicios] = useState([{ servicio: '', importe: '', obs: '' }]);
    const [totalPagosEfectuar, setTotalPagosEfectuar] = useState(0);
    const [arancel, setArancel] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const hasSession = await isAuth();
            if (!hasSession) {
                navegar('/');
            }
        };
        checkAuth();
    }, [navegar]);

    // Calcular totales cuando cambien los servicios o el arancel
    useEffect(() => {
        const totalPagos = servicios.reduce((sum, servicio) => sum + (Number(servicio.importe) || 0), 0);
        setTotalPagosEfectuar(totalPagos);
        setTotal(totalPagos + Number(arancel));
    }, [servicios, arancel]);

    const handleServicioChange = (index, field, value) => {
        const newServicios = [...servicios];
        newServicios[index][field] = value;
        if (field === 'importe') {
            newServicios[index][field] = value.replace(/[^0-9]/g, '');
        }
        setServicios(newServicios);
    };

    const addServicio = () => {
        setServicios([...servicios, { servicio: '', importe: '', obs: '' }]);
    };

    const removeServicio = (index) => {
        if (servicios.length > 1) {
            const newServicios = servicios.filter((_, i) => i !== index);
            setServicios(newServicios);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const userData = {
                nombre: nombre.toUpperCase(),
                numRecibo,
                servicios,
                totalPagosEfectuar,
                arancel: Number(arancel),
                total,
                fecha: fecha || new Date()
            };

            await axios.post('user/adduser', userData);
            
            Swal.fire({
                icon: 'success',
                title: 'Registro guardado',
                text: 'El registro se ha guardado correctamente'
            }).then(() => {
                navegar('/users');
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un error al guardar el registro'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container py-4">
            <div className="card shadow">
                <div className="card-header bg-white">
                    <h4 className="mb-0">Agregar nuevo registro</h4>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row g-3 mb-4">
                            <div className="col-md-4">
                                <label className="form-label">Número de recibo</label>
                                <div className="input-group">
                                    <span className="input-group-text">Nº 0001-</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={numRecibo}
                                        onChange={(e) => setNumRecibo(e.target.value.replace(/[^0-9]/g, ''))}
                                        maxLength="4"
                                        required
                                        placeholder="XXXX"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Nombre y apellido</label>
                                <input
                                    type="text"
                                    className="form-control text-uppercase"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value.toUpperCase())}
                                    required
                                    placeholder="Ej: Juan Pérez"
                                    style={{textTransform: 'uppercase'}}
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Fecha de carga</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={fecha}
                                    onChange={(e) => setFecha(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="card mb-4">
                            <div className="card-header bg-light">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Servicios</h5>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={addServicio}
                                    >
                                        <i className="fas fa-plus me-1"></i>
                                        Agregar servicio
                                    </button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-bordered m-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th style={{width: '35%'}}>Servicio</th>
                                                <th style={{width: '20%'}}>Importe</th>
                                                <th style={{width: '35%'}}>Observación</th>
                                                <th style={{width: '10%'}}>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {servicios.map((servicio, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            value={servicio.servicio}
                                                            onChange={(e) => handleServicioChange(index, 'servicio', e.target.value)}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="input-group input-group-sm">
                                                            <span className="input-group-text">$</span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={servicio.importe}
                                                                onChange={(e) => handleServicioChange(index, 'importe', e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            value={servicio.obs}
                                                            onChange={(e) => handleServicioChange(index, 'obs', e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => removeServicio(index)}
                                                            disabled={servicios.length === 1}
                                                        >
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row g-3 mb-4">
                            <div className="col-md-4">
                                <label className="form-label">Total pagos a efectuar</label>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input
                                        type="text"
                                        className="form-control text-end"
                                        value={totalPagosEfectuar.toLocaleString()}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Arancel</label>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={arancel}
                                        onChange={(e) => setArancel(e.target.value.replace(/[^0-9]/g, ''))}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Total</label>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input
                                        type="text"
                                        className="form-control text-end"
                                        value={total.toLocaleString()}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => navegar('/users')}
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success"
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Guardando...
                                    </>
                                ) : (
                                    <>
                                        <i className="fas fa-save me-2"></i>
                                        Guardar
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddUser;