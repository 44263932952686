import axios from './axios'

export const isAuth = async () => {
    const token = localStorage.getItem('token')
    if(!token) {
        localStorage.removeItem('token');
        return false;
    }
    
    try {
        const response = await axios.get(`auth`);
        if (!response.data) {
            localStorage.removeItem('token');
            return false;
        }
        return true;
    } catch (error) {
        console.error('Error de autenticación:', error);
        localStorage.removeItem('token');
        return false;
    }
}