import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from './axios';
import Swal from 'sweetalert2';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const navegar = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submitSession = async (e) => {
        e.preventDefault();
        setLoading(true);

        const user = {
            email,
            password
        }

        try {
            const res = await axios.post("auth", user);
            if (res.data.token) {
                localStorage.setItem('token', res.data.token);
                navegar('/users');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Credenciales inválidas'
                });
            }
        } catch (error) {
            console.error('Error completo:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.message || 'Error al intentar iniciar sesión'
            });
        } finally {
            setLoading(false);
        }
    }

    return ( 
        <div className="container">
            <form onSubmit={submitSession}>
                <div className="my-4">
                    <label className="form-label" htmlFor="email">Email</label>
                    <input 
                        type="email" 
                        id="email"
                        className="form-control" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                        placeholder="Ingrese su email"
                    />
                </div>
                <div className="my-4">
                    <label className="form-label" htmlFor="password">Contraseña</label>
                    <input 
                        type="password" 
                        id="password"
                        className="form-control" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                        placeholder="Ingrese su contraseña"
                    />
                </div>
                <button 
                    type="submit" 
                    className="my-2 btn btn-info w-100 text-center"
                    disabled={loading}
                >
                    {loading ? 'Ingresando...' : 'Ingresar'}
                </button>
            </form>
        </div>
    );
}

export default Login;