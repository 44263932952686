import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../img/tramites.png';

const User = ({user}) => {
    const fechaFormateada = new Date(user.fecha).toLocaleDateString('es-AR');

    const listaServicios = () => {
        return (
            <table className="table table-sm table-bordered m-0">
                <thead className="table-light">
                    <tr>
                        <th scope="col">Servicio</th>
                        <th scope="col">Nom. Titular</th>
                        <th scope="col">Importe</th>
                        <th scope="col">Observación</th>
                    </tr>
                </thead>
                <tbody>
                    {user.servicios.map((service, index) => (
                        <tr key={index}>
                            <td className="p-1">{service.servicio}</td>
                            <td className="p-1">{service.titular || '-'}</td>
                            <td className="p-1">${service.importe.toLocaleString()}</td>
                            <td className="p-1">{service.obs || '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    const handlePrint = () => {
        const printContent = document.getElementById(`factura-${user._id}`);
        const printWindow = window.open('', '_blank');
        
        printWindow.document.write(`
            <html>
                <head>
                    <title>Factura</title>
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet">
                    <style>
                        @page {
                            size: A4 portrait;
                            margin: 0;
                        }
                        html, body {
                            margin: 0;
                            padding: 0;
                            background-color: white;
                            height: 148.5mm;
                            max-height: 148.5mm;
                            overflow: hidden;
                        }
                        .container {
                            max-width: 100% !important;
                            width: 210mm !important;
                            padding: 0 !important;
                            margin: 0 !important;
                        }
                        .card {
                            border: none !important;
                            box-shadow: none !important;
                            height: 148.5mm !important;
                            max-height: 148.5mm !important;
                            width: 210mm !important;
                            padding: 10mm 15mm 15mm !important;
                            margin: 0 !important;
                            overflow: hidden !important;
                            position: relative !important;
                            page-break-inside: avoid !important;
                            page-break-after: always !important;
                        }
                        .card-body {
                            padding: 0 !important;
                            height: 100% !important;
                            display: flex !important;
                            flex-direction: column !important;
                            position: relative !important;
                        }
                        .table-responsive {
                            overflow: visible !important;
                            margin-bottom: 15mm !important;
                        }
                        .table {
                            font-size: 10px !important;
                            margin-bottom: 0 !important;
                            border-collapse: collapse !important;
                        }
                        p, li { 
                            font-size: 10px !important;
                            margin-bottom: 2px !important;
                            line-height: 1.2 !important;
                        }
                        img {
                            max-height: 20mm !important;
                        }
                        .mt-auto {
                            margin-top: auto !important;
                            position: absolute !important;
                            bottom: 5mm !important;
                            width: calc(100% - 30mm) !important;
                        }
                        .row.g-2 {
                            margin: 0 !important;
                            position: absolute !important;
                            bottom: 5mm !important;
                            width: 100% !important;
                            left: 0 !important;
                            padding: 0 15mm !important;
                        }
                        .bg-success {
                            background-color: #198754 !important;
                            color: white !important;
                        }
                        .card-footer,
                        .pagination,
                        .btn,
                        nav,
                        footer,
                        .shadow-sm,
                        .user-card:hover {
                            display: none !important;
                            transform: none !important;
                            box-shadow: none !important;
                        }
                        * {
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                        }
                        @media print {
                            html, body {
                                width: 210mm;
                                height: 148.5mm;
                                overflow: hidden;
                            }
                            .card {
                                break-inside: avoid;
                                page-break-after: always;
                            }
                            .bg-success {
                                background-color: #198754 !important;
                                color: white !important;
                                -webkit-print-color-adjust: exact !important;
                                print-color-adjust: exact !important;
                            }
                        }
                    </style>
                </head>
                <body>
                    ${printContent.innerHTML}
                </body>
            </html>
        `);
        
        printWindow.document.close();
        printWindow.focus();
        
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 500);
    };

    return ( 
        <div className="card shadow-sm h-100 user-card" id={`factura-${user._id}`}>
            <div className="card-body p-3 d-flex flex-column" style={{ minHeight: '600px' }}>
                {/* Header Section */}
                <div className='row g-0 mb-3'>
                    <div className='col-5 text-center border-end'>
                        <img src={logo} alt="logo" className="img-fluid mb-2" style={{maxWidth: '180px'}} />
                        <div className="small">
                            <p className="fw-bold mb-1">de Cecilia Noemi Raiola</p>
                            <p className="mb-1">Tramitespronto02@gmail.com</p>
                            <p className="mb-1">Moine 1499 (1661) - Bella vista</p>
                            <p className="mb-1">Pcia. de Bs. As - <strong>Cel: 11 2710-3204</strong> <i className="fab fa-whatsapp text-success"></i></p>
                            <p className="mb-0">RESPONSABLE MONOTRIBUTO</p>
                        </div>
                    </div>

                    <div className="col-2 text-center border-end">
                        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                            <h3 className="mb-2">X</h3>
                            <small className="text-muted">Documento no válido como factura</small>
                        </div>
                    </div>

                    <div className="col-5 text-center">
                        <p className="fw-bold mb-2">Comprobante de pago por Cuenta y Orden de terceros</p>
                        <div className="bg-light p-2 mb-2">
                            <p className="mb-1"><strong>Número de recibo:</strong> Nº 0001-{user.numRecibo.padStart(6, '0')}</p>
                            <p className="mb-0"><strong>Fecha de carga:</strong> {fechaFormateada}</p>
                        </div>
                        <div className="small">
                            <p className="mb-1">C.U.I.T: 27-22014816-0</p>
                            <p className="mb-1">Ing. Brutos: 27-22014816-0</p>
                            <p className="mb-0">Inicio Actividades: 10/2017</p>
                        </div>
                    </div>
                </div>

                {/* Client Info Section */}
                <div className="mb-3">
                    <div className="bg-light p-2">
                        <div className="row g-2">
                            <div className="col-12">
                                <strong>Nombre y apellido:</strong> {user.nombre}
                            </div>
                            <div className="col-6">
                                <strong>C.U.I.T:</strong> {user.cuit || '-'}
                            </div>
                            <div className="col-6">
                                <strong>I.V.A:</strong> {user.iva || '-'}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Services Section - Will grow/shrink based on content */}
                <div className="flex-grow-1">
                    <p className="mb-2"><strong>Detalle de servicios:</strong></p>
                    <div className="table-responsive">
                        {listaServicios()}
                    </div>
                </div>

                {/* Totals Section - Always at bottom */}
                <div className="mt-auto">
                    <div className="row g-2">
                        <div className="col-4">
                            <div className="bg-light p-2">
                                <strong>Total pagos:</strong> ${user.totalPagosEfectuar.toLocaleString()}
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="bg-light p-2">
                                <strong>Arancel:</strong> ${user.arancel.toLocaleString()}
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="bg-success text-white p-2">
                                <strong>Total:</strong> ${user.total.toLocaleString()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Action Buttons */}
            <div className="card-footer bg-white border-top d-flex gap-2 justify-content-end">
                <button 
                    onClick={handlePrint}
                    className="btn btn-outline-primary btn-sm"
                >
                    <i className="fas fa-print me-1"></i>
                    Imprimir
                </button>
                <Link 
                    to={`/edituser/${user._id}`} 
                    className="btn btn-warning btn-sm"
                >
                    <i className="fas fa-edit me-1"></i>
                    Editar
                </Link>
            </div>
        </div>
    );
}

export default User;

