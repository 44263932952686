import axios from 'axios';

const prod = process.env.REACT_APP_PROD === "1";
const baseURL = prod ? 'https://tramitespronto.com.ar/api/' : 'http://localhost:5000/api/';

const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers["x-access-token"] = token;
    }
    return config;
});

instance.interceptors.response.use(
    response => response,
    error => {
        console.error('Axios Error:', error.response || error);
        return Promise.reject(error);
    }
);

const CancelToken = axios.CancelToken;

export const source = CancelToken.source();

export const GenerateToken = () => CancelToken.source();

export default instance;