import React, {useState, useEffect, useRef} from 'react';
import axios from './axios';
import { useNavigate } from 'react-router-dom';
import User from './User';
import { isAuth } from './Auth';
import ReactPaginate from 'react-paginate';

// Componente de paginación reutilizable
const Pagination = ({ pageCount, currentPage, onPageChange }) => (
    <div className="d-flex justify-content-center my-3">
        <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            pageCount={pageCount}
            onPageChange={onPageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            disabledClassName={"disabled"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            forcePage={currentPage}
            pageRangeDisplayed={9}
            marginPagesDisplayed={1}
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
        />
    </div>
);

const UserList = () => {
    const [filterUser, setFilterUser] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [nombre, setNombre] = useState('');
    const [fechaD, setFechaD] = useState('');
    const [fechaH, setFechaH] = useState('');
    const navegar = useNavigate();
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [arancelTotal, setArancelTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const listRef = useRef(null);
    
    const getData = async (page = 0) => {
        setLoading(true);
        try {
            const res = await axios.get(`user/obtainuser?page=${page}&nombre=${nombre}&fechaD=${fechaD}&fechaH=${fechaH}`);
            let total = res.data.users.reduce((acc, user) => acc + user.arancel, 0);
            setArancelTotal(total);
            setFilterUser(res.data.users);
            setNumberOfPages(res.data.totalPages);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const isInSession = async () => {
            const hasSession = await isAuth()
            if(!hasSession) {
                navegar('/login')
            }
        }
        isInSession()
    }, [navegar])

    useEffect(() => {
        getData(0);
        setPageNumber(0);
    }, [nombre, fechaD, fechaH])

    const handlePageClick = (event) => {
        const newPage = event.selected;
        setPageNumber(newPage);
        getData(newPage);
        
        // Mantener la posición del scroll
        if (listRef.current) {
            const yOffset = listRef.current.offsetTop - 20;
            window.scrollTo({
                top: yOffset,
                behavior: 'instant'
            });
        }
    };

    const resetFilters = () => {
        setNombre('');
        setFechaD('');
        setFechaH('');
        setPageNumber(0);
    };

    return ( 
        <div className="container py-4">
            <div className="card shadow-sm">
                <div className="card-header bg-white">
                    <h4 className="mb-0">Filtros de búsqueda</h4>
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-md-4">
                            <label className="form-label">Nombre / Apellido</label>
                            <input 
                                value={nombre}
                                onChange={e => setNombre(e.target.value.toUpperCase())} 
                                type="text" 
                                className="form-control text-uppercase" 
                                placeholder="Buscar por nombre..."
                                style={{textTransform: 'uppercase'}}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Fecha desde</label>
                            <input 
                                type="date" 
                                value={fechaD}
                                onChange={e => setFechaD(e.target.value)} 
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">Fecha hasta</label>
                            <input 
                                type="date"
                                value={fechaH}
                                onChange={e => setFechaH(e.target.value)} 
                                className="form-control"
                                min={fechaD}
                            />
                        </div>
                        {(nombre || fechaD || fechaH) && (
                            <div className="col-auto d-flex align-items-end" style={{width: 'auto', paddingLeft: 0}}>
                                <button 
                                    onClick={resetFilters}
                                    className="btn btn-link text-danger d-flex align-items-center justify-content-center"
                                    style={{
                                        fontSize: '28px',
                                        textDecoration: 'none',
                                        padding: '6px',
                                        height: '38px',
                                        minWidth: '38px',
                                        fontWeight: 'bold'
                                    }}
                                    title="Limpiar filtros"
                                >
                                    ×
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-between align-items-center my-4">
                <div className="d-flex align-items-center">
                    <h5 className="mb-0 me-3">Arancel total:</h5>
                    <div className="input-group" style={{width: '200px'}}>
                        <span className="input-group-text">$</span>
                        <input 
                            type="text" 
                            value={arancelTotal.toLocaleString()} 
                            disabled 
                            className="form-control text-end"
                        />
                    </div>
                </div>
                <button 
                    onClick={() => navegar('/adduser')} 
                    className="btn btn-success"
                >
                    <i className="fas fa-plus me-2"></i>
                    Nuevo registro
                </button>
            </div>

            <div ref={listRef}>
                {filterUser?.length > 0 && (
                    <Pagination 
                        pageCount={numberOfPages}
                        currentPage={pageNumber}
                        onPageChange={handlePageClick}
                    />
                )}

                {loading ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="row g-4">
                            {filterUser?.length > 0 ? filterUser.map(user => (
                                <div key={user._id} className="col-md-6">
                                    <User user={user}/>
                                </div>
                            )) : (
                                <div className="col-12">
                                    <div className="alert alert-warning">
                                        No se encontraron registros que coincidan con los filtros aplicados
                                    </div>
                                </div>
                            )}
                        </div>

                        {filterUser?.length > 0 && (
                            <Pagination 
                                pageCount={numberOfPages}
                                currentPage={pageNumber}
                                onPageChange={handlePageClick}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default UserList;