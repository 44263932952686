import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import UserList from './components/UserList';
import EditUser from './components/EditUser';
import AddUser from './components/addUser';
import NavBar from './components/NavBar';
import './components/Styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/edituser/:id" element={<EditUser />} />
        <Route path="/adduser" element={<AddUser />} />
      </Routes>
    </Router>
  </React.StrictMode>
); 